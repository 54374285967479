import React, {Component} from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import classNames from 'classnames'

import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import ExpandMore from '@material-ui/icons/ExpandMore'
import withStyles from '@material-ui/core/styles/withStyles'
import {getLocalTheme} from '../../../helpers/storageHelper'

const brandTheme = getLocalTheme()
const {mainContainer: {backgroundColor}} = brandTheme

const styles = theme => ({
  label: {
    background: backgroundColor || 'white',
    paddingRight: `${theme.spacing.unit}px`
  },
  select: {
    marginBottom: `${theme.spacing.unit * 2}px`,
    maxHeight: 100,
    opacity: 1,
    visibility: 'visible',
    transition: 'all 0.35s'
  },
  hide: {
    opacity: 0,
    visibility: 'hidden',
    maxHeight: 0,
    marginBottom: 0
  }
})

class SelectField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: ''
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount () {
    const {options: {Id}, handleFieldSubmit, preSelectData} = this.props

    if (preSelectData) {
      this.setState({
        value: preSelectData
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: [preSelectData]
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const {preSelectData: prevData, userRequiredOption: prevRequiredOption} = prevProps
    const {options: {Id}, handleFieldSubmit, preSelectData, userRequiredOption, handleUserRequiredIDs} = this.props

    if (prevData !== preSelectData) {
      this.setState({
        value: preSelectData
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: (preSelectData !== undefined) ? [preSelectData] : []
      })
    }

    if (userRequiredOption !== prevRequiredOption) {
      const type = (userRequiredOption) ? 'mandatory' : 'optional'
      handleUserRequiredIDs(type, Id)
    }
  }

  handleChange = (event) => {
    const {options: {Id}, handleFieldSubmit} = this.props

    this.setState({
      value: parseInt(event.target.value)
    }, () => {
      const {value} = this.state

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: (value) ? [value] : []
      })
    })
  }

  render () {
    const {classes, name, id, fieldId, options: {Text: label, SowAnswers}, hide} = this.props
    const question = `questions.${id}`

    return (
      <FormControl variant='outlined' className={classNames(classes.select, {[classes.hide]: hide})}>
        <InputLabel className={classes.label} ref={ref => { this.InputLabelRef = ref }} htmlFor={name}>
          {i18n.t(`${question}.text`)}
        </InputLabel>
        <Select
          native
          value={(this.state.value) ? this.state.value : ''}
          onChange={this.handleChange}
          IconComponent={ExpandMore}
          input={
            <OutlinedInput
              labelWidth={label.length * 7.5}
              name={name}
              id={fieldId}
            />
          }
        >
          <option value=''></option>
          {SowAnswers.map(({Id}) => {
            return <option key={Id} value={Id}>{i18n.t(`${question}.answers.${Id}`)}</option>
          })}
        </Select>
      </FormControl>
    )
  }
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  fieldId: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  hide: PropTypes.bool
}

export default withStyles(styles)(SelectField)
