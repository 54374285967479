import React, {Component} from 'react'
import i18n from 'i18n-js'

import SelectField from '../components/SelectField'
import MultipleOptionsField from '../components/MultipleOptionsField'
import RadioOptionsField from '../components/RadioOptionsField'
import StatusLabel from '../../Common/components/StatusLabel'

import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

import Wrapper from '../../Common/components/Wrapper'
import AppBar from '../../Common/components/AppBar'
import Button from '../../Common/components/Button'
import FAQLink from '../../Common/components/FAQLink'
import WithdrawFunds from '../../Common/components/WithdrawFunds'

import {
  SOW_QUESTION_TYPE_ID,
  CURRENT_COMPONENT,
  SOW_QUESTIONS,
  EMPLOYMENT_TO_SHOW,
  EMPLOYMENT_WITH_OCCUPATION,
  EMPLOYMENT_STATUS_ID,
  OCCUPATION_ID,
  SOW_QUESTIONS_FIELD_IDS,
  LICENSES
} from '../../../constants'
import {getErrorMessage} from '../../../handlers/errorHandler'
import {htmlToReactParser, getSOWQNote} from '../../../helpers/stringHelper'

const styles = theme => ({
  form: {
    margin: `${theme.spacing.unit * 2}px 0`,
  },
  info: {
    margin: `${theme.spacing.unit * 2}px 0`
  },
  infoLink: {
    textAlign: 'center',
    margin: `${theme.spacing.unit * 2}px 0`
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingText: {
    marginLeft: theme.spacing.unit
  }
})

class Questionnaire extends Component {
  constructor (props) {
    super(props)

    this.state = {
      questions: [],
      sowAnswers: [],
      canSubmit: false,
      userRequiredIDs: [],
      submitted: false
    }

    this.handleUserRequiredIDs = this.handleUserRequiredIDs.bind(this)
    this.handleFieldSubmit = this.handleFieldSubmit.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  componentDidMount () {
    const {sowApiDataLayer, handleError, gtmEvent} = this.props
    let {questions} = this.state

    if (sowApiDataLayer && questions.length === 0) {
      sowApiDataLayer.Kyc.getSowQuestionnaire().then((data) => {
        const {SowQuestions} = data

        const mandatory = SowQuestions.filter(
          question => question.SowQuestionType !== SOW_QUESTION_TYPE_ID.MULTIPLE_OR_NO_ANSWER
        )

        const optional = SowQuestions.filter(
          question => question.SowQuestionType === SOW_QUESTION_TYPE_ID.MULTIPLE_OR_NO_ANSWER
        )

        this.setState({
          questions: [...mandatory, ...optional],
        })

        gtmEvent({
          eventAction: 'Questionnaire Opened'
        })
      }).catch((error) => {
        handleError(getErrorMessage(error))
      })
    }
  }

  handleUserRequiredIDs (type, questionId) {
    let data
    let userRequiredIDs = this.state.userRequiredIDs

    if (type === 'mandatory') {
      userRequiredIDs.push(questionId)
    }

    if (type === 'optional') {
      data = { SowQuestionId: questionId, SowAnswerIds: [] }
      userRequiredIDs = userRequiredIDs.filter(id => id !== questionId)
    }

    this.setState(
      { userRequiredIDs },
      () => this.handleFieldSubmit(data)
    )
  }

  handleFieldSubmit (data) {
    let {questions, sowAnswers, userRequiredIDs} = this.state

    if (data) {
      const index = sowAnswers.findIndex((question) => question.SowQuestionId === data.SowQuestionId)
  
      if (index === -1) {
        sowAnswers.push(data)
      } else {
        sowAnswers[index] = data
      }
    }

    const mandatory = questions.filter(
      question => question.SowQuestionType !== SOW_QUESTION_TYPE_ID.MULTIPLE_OR_NO_ANSWER
    )
    const mandatoryIds = mandatory.map(question => question.Id)
    mandatoryIds.push(...userRequiredIDs)

    const answers = sowAnswers.filter(answer => answer.SowAnswerIds.length > 0)
    const answersIds = answers.map(answer => answer.SowQuestionId)

    this.setState({
      sowAnswers,
      canSubmit: (mandatoryIds.every((id) => answersIds.includes(id)))
    })
  }

  async handleFormSubmit () {
    const {questions, sowAnswers} = this.state
    const {sowApiDataLayer, handleError, gtmEvent, resubmitSOWQ, tagId, updateTopLevelState} = this.props

    try {
      const SowQuestions = questions.map((question) => {
        const answer = sowAnswers.find((answer) => answer.SowQuestionId === question.Id)
        const sowAnswerIds = (answer) ? answer.SowAnswerIds : []
  
        return {
          SowQuestionId: question.Id,
          SowAnswerIds: sowAnswerIds
        }
      })
  
      let sowqSubmitted
      // if resubmitSOWQ -> send via User Notes
      if (resubmitSOWQ) {
        sowqSubmitted = await sowApiDataLayer.User.addUserNote(getSOWQNote(SowQuestions))
        // remove User Tag
        await sowApiDataLayer.Tags.unassignUserTag(tagId)

        updateTopLevelState({resubmitSOWQ: false})
      } else {
        sowqSubmitted = await sowApiDataLayer.Kyc.postSowQuestionnaire({SowQuestions})
      }

      if (sowqSubmitted) {
        this.setState({
          submitted: true
        })
  
        gtmEvent({
          eventAction: 'Questionnaire Submitted'
        })
      }
    }
    catch(error) {
      handleError(getErrorMessage(error))
    }
  }

  renderQuestions () {
    const {questions} = this.state
    const {countryId, hiddenQuestions, license} = this.props

    if (questions.length > 0) {
      return questions.map((question) => {
        const {sowAnswers} = this.state
        const {Id, SowQuestionType, SowAnswers} = question
        let FieldComponent = SelectField
        let fieldProps = {}
        let hide = hiddenQuestions.includes(Id)

        // get employment answers
        const employmentAnswers = sowAnswers.find(a => {
          return a.SowQuestionId === SOW_QUESTIONS.EMPLOYMENT && a.SowAnswerIds.length > 0
        })

        const sourcesOfIncomeAnswers = sowAnswers.find(a => {
          return a.SowQuestionId === SOW_QUESTIONS.SOURCES_OF_INCOME && a.SowAnswerIds.length > 0
        })

        const sourcesOfIncomeTotalAnswer = sowAnswers.find(a => {
          return a.SowQuestionId === SOW_QUESTIONS.SOURCES_OF_INCOME_TOTAL && a.SowAnswerIds.length > 0
        })

        // TODO: consider moving switch/case logic to separate file

        // switch/case to render necessary component
        switch (SowQuestionType) {
          case SOW_QUESTION_TYPE_ID.MULTIPLE_ANSWER:
          case SOW_QUESTION_TYPE_ID.MULTIPLE_OR_NO_ANSWER:
            FieldComponent = MultipleOptionsField
            break
          default:
            FieldComponent = (SowAnswers.length > 2) ? SelectField : RadioOptionsField
            break
        }

        // switch/case for field props
        switch (Id) {
          case SOW_QUESTIONS.EMPLOYMENT:
            FieldComponent = SelectField
            question.SowAnswers = SowAnswers.filter(answer => EMPLOYMENT_TO_SHOW.includes(answer.Id))
            fieldProps = {preSelectData: 2}
            break
          case SOW_QUESTIONS.OCCUPATION:
            fieldProps = {}

            // if answers include employment with occupation, display occupation question
            if (employmentAnswers) {
              const withOccupation = employmentAnswers.SowAnswerIds.some(id => {
                return EMPLOYMENT_WITH_OCCUPATION.includes(id)
              })

              if (!withOccupation) {
                const unemployed = employmentAnswers.SowAnswerIds.includes(EMPLOYMENT_STATUS_ID.UNEMPLOYED)
                const retired = employmentAnswers.SowAnswerIds.includes(EMPLOYMENT_STATUS_ID.RETIRED)

                fieldProps = {
                  preSelectData: (unemployed) ? OCCUPATION_ID.UNEMPLOYED : (retired) ? OCCUPATION_ID.RETIRED : null
                }
              }

              hide = !withOccupation
            }
            break
          case SOW_QUESTIONS.ANNUAL_EARNINGS:
            FieldComponent = SelectField
            hide = (license === LICENSES.SGA)
            break
          case SOW_QUESTIONS.BIRTH_PLACE:
          case SOW_QUESTIONS.NATIONALITY:
            fieldProps = {preSelectData: countryId}
            break
          case SOW_QUESTIONS.RESIDENTIAL_PROPERTY:
            fieldProps = {preSelectData: '87'} // SowAnswers - id for "No"
            break
          case SOW_QUESTIONS.SOURCES_OF_INCOME:
            fieldProps = {
              userRequiredOption: true,
              // preOptionsText: i18n.t('questions.4.preOptionsText'),
              handleUserRequiredIDs: this.handleUserRequiredIDs,
            }
            break
          case SOW_QUESTIONS.SOURCES_OF_INCOME_TOTAL:
            FieldComponent = SelectField

            const hasSourcesOfIncome = (sourcesOfIncomeAnswers && sourcesOfIncomeAnswers.SowAnswerIds.length > 0)
            const hasSourcesOfIncomeTotal = (sourcesOfIncomeTotalAnswer && sourcesOfIncomeTotalAnswer.SowAnswerIds.length > 0)

            fieldProps = {
              userRequiredOption: !!sourcesOfIncomeAnswers,
              handleUserRequiredIDs: this.handleUserRequiredIDs,
              preSelectData: (hasSourcesOfIncome && hasSourcesOfIncomeTotal) ? sourcesOfIncomeTotalAnswer.SowAnswerIds[0] : null
            }

            hide = !hasSourcesOfIncome
            break
          default:
            fieldProps = {}
            break
        }

        return <FieldComponent
          key={`question${Id}`}
          name={`question${Id}`}
          id={Id}
          fieldId={SOW_QUESTIONS_FIELD_IDS[Id]}
          options={question}
          handleFieldSubmit={this.handleFieldSubmit}
          hide={hide}
          {...fieldProps}
        />
      })
    }
  }

  renderQuestionnaire () {
    const {classes, updateTopLevelState, amlStatus, gtmEvent, license} = this.props
    const {canSubmit} = this.state

    return <React.Fragment>
      <div className={classes.form}>
        <form id='QuestionnaireForm'>
          <FormGroup>
            {this.renderQuestions()}
            <Button variant='contained' color='primary' onClick={this.handleFormSubmit} disabled={!canSubmit}>
              {i18n.t('questionnaire.cta')}
            </Button>
          </FormGroup>
        </form>
      </div>
      <Divider />
      <div className={classes.info}>
        <Typography variant='h2'>{i18n.t('questionnaire.info.title')}</Typography>
        <Typography paragraph={true}>
          {htmlToReactParser(i18n.t('questionnaire.info.description'))}
        </Typography>
        <WithdrawFunds amlStatus={amlStatus} gtmEvent={gtmEvent} license={license} /><br />
        <FAQLink updateTopLevelState={updateTopLevelState} gtmEvent={gtmEvent} />
      </div>
    </React.Fragment>
  }

  renderQuestionnaireComplete () {
    const {classes, updateTopLevelState} = this.props

    setTimeout(() => updateTopLevelState({currentComponent: CURRENT_COMPONENT.DOCUMENTS}), 5000)

    return <React.Fragment>
      <Typography variant='h1'>{i18n.t('questionnaire.complete.title')}</Typography>
      <Typography paragraph={true}>{htmlToReactParser(i18n.t('questionnaire.complete.text'))}</Typography>
      <StatusLabel
        label={i18n.t('status-info.label.questionnaire')}
        info={i18n.t('status-info.info.today')}
        status='success'
        statusText={i18n.t('status-info.status.submitted')} />
      <div className={classes.loadingContainer}>
        <CircularProgress size={30} />
        <Typography variant='h3' className={classes.loadingText}>{i18n.t('questionnaire.complete.loading')}</Typography>
      </div>
    </React.Fragment>
  }

  render () {
    const {amlStatus, updateTopLevelState, debug, gtmEvent} = this.props
    const {questions, submitted} = this.state
    let key = 'loading'
    let renderQuestionnaire = <div />

    if (questions.length > 0) {
      if (submitted) {
        key = 'complete'
        renderQuestionnaire = this.renderQuestionnaireComplete()
      } else {
        key = 'questionnaire'
        renderQuestionnaire = this.renderQuestionnaire()
      }
    }

    return (
      <React.Fragment>
        <AppBar
          position='sticky'
          title={i18n.t('questionnaire.title')}
          amlStatus={amlStatus}
          updateTopLevelState={updateTopLevelState}
          debug={debug}
          gtmEvent={gtmEvent}
        />
        <Wrapper useTransitionGroup={true} transitionKey={key}>
          {renderQuestionnaire}
        </Wrapper>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Questionnaire)
