import React, {Component} from 'react'
import queryString from 'query-string'
import classNames from 'classnames'
import TagManager from 'react-gtm-module'
import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import {ReactPlugin, withAITracking} from '@microsoft/applicationinsights-react-js'
import {createBrowserHistory} from 'history'

import SowApiDataLayer from '../../../data/SowApiDataLayer'
import i18n from 'i18n-js'

import Theme from '../components/Theme'

import SOWStatusChecks from '../components/SOWStatusChecks'
import Questionnaire from '../../Questionnaire/containers/Questionnaire'
import Documents from '../../Documents/containers/Documents'
import Countdown from '../../CountDown/containers/Countdown'
import DepositLimits from '../../DepositLimits/containers/DepositLimits'

import FAQs from '../../FAQs/containers/FAQs'
import UploadHelp from '../../UploadHelp/components/UploadHelp'
import ErrorMessage from '../../Common/components/ErrorMessage'

import withStyles from '@material-ui/core/styles/withStyles'
import request from 'request-promise-native'

import '../App.scss'
import {
  CURRENT_COMPONENT,
  SOW_QUESTIONS,
  SUPPORTED_LANGS,
  COUNTRY_LICENSES,
  LICENSES,
  POST_MESSAGE,
  RESUBMIT_SOWQ_TAG_ID
} from '../../../constants'
import {getLocalTheme} from '../../../helpers/storageHelper'
import {getGTMCategory} from '../../../helpers/GTMHelper'
import {postMessage} from '../../../handlers/messageHandler'

const browserHistory = createBrowserHistory({basename: ''})
var reactPlugin = new ReactPlugin()
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: 'd4ee71ce-853d-4b04-977c-1ae7b28fd3f1',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {
        history: browserHistory
      }
    }
  }
})
appInsights.loadAppInsights()

const brandTheme = getLocalTheme()

const styles = theme => ({
  root: {
    width: 600,
    height: '90vh',
    maxHeight: 750,
    margin: 'auto',
    backgroundColor: 'white',
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
      height: '100vh',
      maxHeight: 'none'
    },
  },
  brand: brandTheme.mainContainer
})

class App extends Component {
  constructor (props) {
    super(props)

    const {location: {search}} = window

    // Save query string in state
    const {
      authenticationToken = 'c0923ec5-c9a5-4b50-974c-5e32b53a9df8',
      brand = 'guts',
      userId = null,
      countryCode = 'GB', // default countryCode - GB
      language = 'en',
      currency = 'EUR',
      interval,
      apiEndpoint = 'http://localhost:8181',
      apiKey = 'a3c9c5e7-7e68-4730-a927-bb41dedba50b',
      debug = '0',
      clearCache = '0',
      translations,
      hiddenQuestions = [SOW_QUESTIONS.RESIDENTIAL_PROPERTY, SOW_QUESTIONS.SOURCES_OF_INCOME],
      isStaging,
      loadGTM = false
    } = queryString.parse(search, {
      arrayFormat: 'comma',
      parseNumbers: true,
      parseBooleans: true,
    })

    let _interval = (isStaging && !interval) ? 60 : 300

    this.updateAppState = this.updateAppState.bind(this)
    this.updateTopLevelState = this.updateTopLevelState.bind(this)
    this.handleError = this.handleError.bind(this)
    this.gtmSOWConfig = this.gtmSOWConfig.bind(this)
    this.gtmEvent = this.gtmEvent.bind(this)
    this.messageListener = this.messageListener.bind(this)

    // initialize IGC Data Layer
    this.sowApiDataLayer = SowApiDataLayer.init(
      apiEndpoint,
      apiKey,
      authenticationToken
    )

    const getLocalState = (debug === '1' && clearCache === '0')
    const cachedState = (getLocalState) ? JSON.parse(localStorage.getItem('sow-appstate') || '{}') : {}

    const initialState = {
      app: {
        userId,
        countryCode,
        language,
        currency,
        authenticationToken,
        interval: _interval * 1000,
        translations,
        hiddenQuestions: hiddenQuestions === false ? [] : this.getHiddenQuestions(hiddenQuestions),
        isStaging,
        loadGTM
      },
      sowStatus: {
        amlStatus: 0,
        gracePeriod: -1,
        gracePeriodType: ''
      },
      resubmitSOWQ: false,
      userTags: [],
      depositPercentage: -1,
      brand,
      user: {},
      limits: {},
      i18n,
      showError: false,
      errorMessage: '',
      debug,
      pendingApprovalTypes: [],
      processingApprovalTypes: [],
      approvedApprovalTypes: [],
      currentComponent: CURRENT_COMPONENT.COUNTDOWN
    }
    /* initial app state */
    this.state = {...initialState, ...cachedState}
  }

  async componentWillMount () {
    const {location: {protocol, host}} = window
    const {app: {language, translations, countryCode, loadGTM}} = this.state
    i18n.defaultLocale = 'en'
    i18n.locale = (SUPPORTED_LANGS.includes(language)) ? language : i18n.defaultLocale
    i18n.fallbacks = true

    const localJSON = (SUPPORTED_LANGS.includes(language)) ? `sow-${language}.json` : `sow-en.json`
    const translationsURL = translations || `${protocol}//${host}/translations/${localJSON}`
    i18n.translations = await request.get(translationsURL, {json: true})

    let countryData
    if (countryCode) {
      countryData = await this.sowApiDataLayer.Countries.getCountry({countryCode})
    }

    this.setState(prevState => {
      return {
        i18n,
        app: {
          ...prevState.app,
          countryId: (countryData) ? countryData.CountryID : -1,
          license: COUNTRY_LICENSES[countryCode] || LICENSES.MGA
        }
      }
    })

    if (loadGTM) {
      this.gtmSOWConfig()
      this.gtmEvent({event: 'SOW.ready', eventAction: 'Ready'})
    }

    window.addEventListener('message', this.messageListener, false)
  }

  messageListener (message) {
    const {data} = message
    switch (data) {
      case 'SOW.SHOW_SOWQ':
        this.setState({
          currentComponent: CURRENT_COMPONENT.QUESTIONNAIRE,
          showSOWQDocs: true
        })
        break
        case 'SOW.SHOW_RESUBMIT_SOWQ':
          this.setState({
            currentComponent: CURRENT_COMPONENT.COUNTDOWN,
            showSOWQDocs: true,
            resubmitSOWQ: true
          })
          break
      default:
        break
    }
  }

  updateTopLevelState (newState) {
    this.setState(prevState => {
      const nextState = {
        ...prevState, ...newState
      }
      localStorage.setItem('sow-appstate', JSON.stringify(nextState))
      this.postSOWData(prevState, nextState)
      return {...nextState}
    })
  }

  postSOWData (prev, next) {
    const {sowStatus: prevSOWStatus, sowDisplayed: prevDisplayed} = prev
    const {sowStatus, sowDisplayed} = next

    const prevData = {...prevSOWStatus, sowDisplayed: prevDisplayed}
    const sowData = {...sowStatus, sowDisplayed}

    if (JSON.stringify(prevData) !== JSON.stringify(sowData)) {
      postMessage({type: POST_MESSAGE.DATA, sowData})
    }
  }

  updateAppState (key, data) {
    this.setState({
      [key]: data
    })
  }

  handleError (message) {
    this.setState({
      showError: true,
      errorMessage: message
    })
  }

  getHiddenQuestions (hiddenQuestions) {
    if (Array.isArray(hiddenQuestions)) {
      return hiddenQuestions
    }

    return [hiddenQuestions]
  }

  gtmSOWConfig () {
    const {
      app: {countryCode, isStaging, loadGTM},
      brand
    } = this.state

    if (loadGTM) {
      window.SOWconfig = {}
      window.SOWconfig.product = brand
      window.SOWconfig.environment = isStaging ? 'staging' : 'production'
      window.SOWconfig.license = COUNTRY_LICENSES[countryCode] || LICENSES.MGA
      // window.SOWconfig.furtherAction
    }
  }

  gtmEvent ({event, eventAction, eventLabel}) {
    const {
      app: {userId, loadGTM},
      sowStatus: {amlStatus, gracePeriod, gracePeriodType},
    } = this.state

    if (loadGTM) {
      TagManager.dataLayer({
        dataLayer: {
          event: event || 'SOW.form',
          amlStatus,
          gracePeriod: (gracePeriod !== -1) ? gracePeriod : null,
          gracePeriodType: gracePeriodType || null,
          userId: userId || null,
          eventCategory: getGTMCategory(amlStatus, gracePeriodType), // SOWQ, SOWD, SOWF
          eventAction,
          eventLabel: eventLabel || null
        },
        dataLayerName: 'SOW_dataLayer'
      })
    }
  }

  render () {
    const {sowApiDataLayer} = this
    const {
      app: {interval, currency, countryId, countryCode, hiddenQuestions, license, isStaging},
      sowStatus: {amlStatus, gracePeriod, gracePeriodType},
      resubmitSOWQ,
      userTags,
      sowDisplayed,
      depositPercentage,
      i18n,
      showError,
      errorMessage,
      debug,
      pendingApprovalTypes,
      processingApprovalTypes,
      approvedApprovalTypes,
      currentComponent,
      brand,
      showSOWQDocs
    } = this.state

    const {classes} = this.props

    let tagId
    const tagIdData = RESUBMIT_SOWQ_TAG_ID[countryCode.toUpperCase()] || undefined

    if (tagIdData) {
      tagId = (isStaging) ? tagIdData.staging : tagIdData.production
    }

    const props = {
      sowApiDataLayer: sowApiDataLayer,
      brand,
      license,
      interval,
      amlStatus,
      gracePeriod,
      gracePeriodType,
      resubmitSOWQ,
      tagId,
      userTags,
      sowDisplayed,
      depositPercentage,
      i18n,
      currency,
      updateAppState: this.updateAppState,
      handleError: this.handleError,
      debug,
      pendingApprovalTypes,
      processingApprovalTypes,
      approvedApprovalTypes,
      updateTopLevelState: this.updateTopLevelState,
      gtmEvent: this.gtmEvent,
      showSOWQDocs
    }

    return (
      <div className={classNames(classes.root, classes.brand)}>

        {sowApiDataLayer && <SOWStatusChecks {...props} />}
        <Theme>
          {(() => {
            switch (currentComponent) {
              case CURRENT_COMPONENT.COUNTDOWN:
                return <Countdown {...props} />
              case CURRENT_COMPONENT.QUESTIONNAIRE:
                return <Questionnaire {...props} countryId={countryId} hiddenQuestions={hiddenQuestions} />
              case CURRENT_COMPONENT.DOCUMENTS:
                return <Documents {...props} />
              case CURRENT_COMPONENT.DEPOSIT_LIMITS:
                return <DepositLimits {...props} />
              case CURRENT_COMPONENT.HELP_FAQS:
                return <FAQs
                  gracePeriod={gracePeriod}
                  updateTopLevelState={this.updateTopLevelState}
                  gtmEvent={this.gtmEvent}
                />
              case CURRENT_COMPONENT.HELP_UPLOADS:
                return <UploadHelp
                  gracePeriod={gracePeriod}
                  updateTopLevelState={this.updateTopLevelState}
                  gtmEvent={this.gtmEvent}
                />
              default:
                return null
            }
          })()}

          <ErrorMessage open={showError} errorMessage={errorMessage} updateAppState={this.updateAppState} />
        </Theme>
      </div>
    )
  }
}

export default withAITracking(reactPlugin, withStyles(styles)(App))
